import React from "react";
import { Container, Stack } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles for the image carousel
import { PageTitle } from "../components/PageTitle";
import { AchievementCards } from "../components/AchievementCards";
import highlights from "../data/Highlights.json";

export default function Highlights() {
  const highlightTypes = [
    {
      type: "achievements",
      displayName: "Achievements",
    },
    {
      type: "publications",
      displayName: "Publications",
    },
    {
      type: "contributions",
      displayName: "Contributions",
    },
  ];
  return highlightTypes.map((item) => {
    return (
      <Container className="page-container" key={item.type} id={item.type}>
        <Stack gap={5}>
          <PageTitle title={item.displayName}></PageTitle>
          <AchievementCards
            achievements={highlights.filter(
              (highlight) => highlight.type === item.type
            )}
          />
        </Stack>
      </Container>
    );
  });
}
