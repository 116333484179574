import React from "react";
import { Container, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { FaChevronDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

export default function OldHome() {
  const isDesktop = useMediaQuery({ minWidth: 992 }); // Bootstrap lg breakpoint

  return (
    <Container
      className="page-container"
      style={{ height: "calc(100vh - 77px)", margin: 0, padding: 0 }}
      id="home"
    >
      {isDesktop ? (
        // Desktop Layout
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "25%",
              width: "75%",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Image
              src="/images/2024-team-eindhoven.jpg"
              style={{ maxWidth: "100%", height: "auto" }}
              alt="2024 Team Eindhoven"
            />
          </div>
          <div
            className="hero-title"
            style={{
              position: "absolute",
              top: "50%",
              right: "70%",
              width: "30%",
              maxWidth: "480px",
              marginLeft: "5%",
              padding: "10px",
              transform: "translateY(-50%)",
            }}
          >
            <h2 style={{ padding: "10px" }}>We are the RMIT RedbackBots</h2>
            <h4 style={{ padding: "10px", color: "black" }}>
              A relatively new, but dedicated team of RMIT students who program
              autonomous robots to play soccer.
            </h4>
          </div>
          <Nav.Link
            as={Link}
            to={"/#achievements"}
            style={{
              position: "absolute",
              bottom: "10%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <FaChevronDown size={30} />
          </Nav.Link>
        </>
      ) : (
        // Mobile Layout
        <>
          <div
            style={{
              position: "absolute",
              top: "20%",
              left: 0,
              display: "flex",
              alignItems: "center",
              height: "100vh",
              flexDirection: "column",
            }}
          >
            <div>
              <Image
                src="/images/2024-team-eindhoven.jpg"
                style={{ maxWidth: "100%", height: "auto" }}
                alt="2024 Team Eindhoven"
              />
              <div className="hero-title">
                <h2 style={{ padding: "10px" }}>We are the RMIT RedbackBots</h2>
                <h4 style={{ padding: "10px", color: "black" }}>
                  A relatively new, but dedicated team of RMIT students who
                  program autonomous robots to play soccer.
                </h4>
              </div>
            </div>
            <Nav.Link
              as={Link}
              to={"/#achievements"}
              style={{ marginTop: "10%" }}
            >
              <FaChevronDown size={30} />
            </Nav.Link>
          </div>
        </>
      )}
    </Container>
  );
}
