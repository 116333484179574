import { Container, Row } from "react-bootstrap";

export function PreviousTeams({ team, index }) {
  return (
    <Container key={index}>
      <h3 className="team-year"><b>{team.year}</b></h3>
      <hr />
      <Container style={{ marginLeft: "20px" }}>
        <h5>Supervisors:</h5>
        <Container style={{ marginLeft: "30px" }}>
          <Row xs={2} md={3}>
            {team.supervisors.map((supervisor, index) => {
              return <p key={index}>{supervisor}</p>;
            })}
          </Row>
        </Container>
        <h5>Team Members:</h5>
        <Container style={{ marginLeft: "30px" }}>
          <Row xs={2} md={3} lg={6}>
            {team.team_members.map((team_member, index) => {
              return <p key={index}>{team_member}</p>;
            })}
          </Row>
        </Container>
        <h5>Results:</h5>
        <Container style={{ marginLeft: "30px" }}>{team.results}</Container>
      </Container>
    </Container>
  );
}
