import React, { useEffect, useRef } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./pages/About";
import Sponsorships from "./pages/Sponsorships";
import Links from "./pages/Links";
import Privacy from "./pages/Privacy";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./App.css";
import { Join } from "./pages/Join";
import { Container, Stack } from "react-bootstrap";
import ScrollToHash from "./components/ScrollToHash";
import Highlights from "./pages/Highlights";

function App() {
  const headerRef = useRef(null);

  // https://schema.org/Article
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    image: "https://redbackbots.com/images/RedbacksLogo.png",
    url: "https://redbackbots.com",
    logo: "https://redbackbots.com/images/RedbacksLogo.png",
    name: "RMIT RedbackBots",
    description: "RMIT's Robot Soccer Team",
    email: "STEM.RedbackBots@rmit.edu.au",
    foundingDate: "2020-01-01",
  };
  useEffect(() => {
    console.log("useeffect", headerRef);
  }, [headerRef]);

  useEffect(() => {
    const handleScroll = (event) => {
      console.log('Page scrolled:', window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <ScrollToHash headerRef={headerRef} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(articleStructuredData),
        }}
      />
      <Stack style={{ minHeight: "100vh", backgroundColor: "black" }}>
        <Header headerRef={headerRef} />
        <Container className="page-container">
          <Routes>
            <Route path="/about" element={<Navigate to="/#about" replace/>} />
            <Route path="/achievements" element={<Navigate to="/#achievements" replace/>} />
            <Route path="/publications" element={<Navigate to="/#publications" replace/>} />
            <Route path="/games" element={<Navigate to="/#games" replace/>} />
            <Route path="/contributions" element={<Navigate to="/#contributions" replace/>} />
            <Route path="/sponsorships" element={<Navigate to="/#sponsorships" replace/>} />
            <Route path="/about" element={<Navigate to="/#about" replace/>} />
            <Route path="/join" element={<Navigate to="/#join" replace/>} />
            <Route path="links" element={<Links/>} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/" element={<>
              <Home />
          <Highlights />
          <Sponsorships />
          <About />
          <Join />
            </>} />
          </Routes>
         
        </Container>
        <Footer />
      </Stack>
    </Router>
  );
}

export default App;
