import React from "react";
import { Container, Stack } from "react-bootstrap";
import { PageTitle } from "../components/PageTitle";

export function Join() {

  return (
    <Container className="page-container"  id="join">
      <Stack gap={3}>
        <PageTitle title={"Join The Team"} />
        <Container>
          If you are an RMIT student and are interested in joining the team, submit the following form to express your interest!
          <br></br>
          You need to be signed in to your RMIT account to see the form below, otherwise access it <a href="https://forms.office.com/pages/responsepage.aspx?id=cTYy0b7NF0S01L2yS1Exa3mlWDjWXB9MuyTSTDgigOFUMjFTMkxISDRTTkZHSlYwUzRNQkRJUUc5NC4u">here</a>
        </Container>
        <Container>
        <iframe
          title="Expression of Interest Form"
          width="100%"
          height="900px"
          id="interestFormIframe"
          src="https://forms.office.com/pages/responsepage.aspx?id=cTYy0b7NF0S01L2yS1Exa3mlWDjWXB9MuyTSTDgigOFUMjFTMkxISDRTTkZHSlYwUzRNQkRJUUc5NC4u&embed=true"
          frameborder="0"
          marginwidth="0"
          marginheight="0"
          style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
          webkitallowfullscreen
          mozallowfullscreen
          msallowfullscreen
        >
          {" "}
        </iframe>
        </Container>
      </Stack>
    </Container>
  );
}
