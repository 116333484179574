import React from "react";
import { Container, Row, Col, Card, Stack } from "react-bootstrap";
import { PageTitle } from "../components/PageTitle";
import { SponsorForm } from "../components/SponsorForm";

export default function Sponsorships() {
  const goldTierStyle = { color: "#FFD700"};
  const silverTierStyle = { color: "#C0C0C0"};
  const bronzeTierStyle = { color: "#CD7F32"};

  const contactEmail = "STEM.redbackbots@rmit.edu.au";

  const showSponsorTiers = true;

  var runningOnBitbucket = window.location.href.includes("bitbucket");

  // Disable contact form for now
  runningOnBitbucket = true;

  console.log(window.location.href);
  return (
    <Container className="page-container" id="sponsorships">
      <Stack gap={3}>
        <PageTitle title={"Sponsorships"}></PageTitle>
        <Row>
          <Container className="text-center">
            <h5>
              <span className="text-primary">
                <b>Support</b>
              </span>{" "}
              our Student Led Robot Soccer Team
            </h5>
          </Container>
        </Row>
        <Row>
          <Col lg={6} style={{ marginTop: 50 }}>
            <Container className="text-center">
              <h5>
                <span className="text-primary">
                  <b>International</b>
                </span>{" "}
                exposure at RoboCup
              </h5>
              <div>2500+ Compeitors</div>
              <div>42+ Countries</div>
              <div>3000+ Onsite Visitors</div>
              <div>Global Live Streaming</div>
            </Container>
          </Col>
          <Col lg={6} style={{ marginTop: 50 }}>
            <Container className="text-center">
              <h5>
                <span className="text-primary">
                  <b>Highlight</b>
                </span>{" "}
                at RMIT events
              </h5>
              <div>Open Day</div>
              <div>Victoria Outreach</div>
              <div>TV & Streamed Events</div>
            </Container>
          </Col>
        </Row>
        {showSponsorTiers && (
          <Row className="mt-4">
            <Col lg={4}>
              <Card
                className="mb-4"
                style={{
                  minHeight: 240,
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
              >
                {/* <Card.Header
                  as="h2"
                  style={goldTierStyle}
                  className="text-center"
                >
                  Gold Sponsorship
                </Card.Header> */}
                <Card.Title><Stack direction="horizontal" className="ms-auto" style={{justifyContent: "space-between"}}><b style={goldTierStyle}>Gold Sponsorship</b><div>$5,000</div></Stack></Card.Title>

                <div
                    className="impact-line"
                    style={{
                      width: "100%",
                      height: "3px",
                      marginBottom: "10px",
                      display: "inline-block",
                      float: "right",
                    }}
                  />
                <Card.Body>

                  <Card.Text>
                    Benefits:
                    <ul>
                      <li>Brand Logo on robot leg</li>
                      <li>Brand Logo on team shirts</li>
                      <li>Primary sponsor listing</li>
                      <li>Individual negotiated social media shoutout</li>
                      <li>+ Silver Benefits</li>
                    </ul>
                  </Card.Text>
                </Card.Body>

             
              </Card>
            </Col>

            <Col lg={4}>
              <Card
                className="mb-4"
                style={{
                  minHeight: 240,
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
              >
                {/* <Card.Header
                  as="h2"
                  style={silverTierStyle}
                  className="text-center"
                >

                  Silver Sponsorship
                </Card.Header> */}
                <Card.Title><Stack direction="horizontal" className="ms-auto" style={{justifyContent: "space-between"}}><b style={silverTierStyle}>Silver Sponsorship</b><div>$3,000</div></Stack></Card.Title>

                <div
                    className="impact-line"
                    style={{
                      width: "100%",
                      height: "3px",
                      marginBottom: "10px",
                      display: "inline-block",
                      float: "right",
                    }}
                  />
                <Card.Body>
                 
                  <Card.Text>
                    Benefits:
                    <ul>
                      <li>Second sponsor listing</li>
                      <li>
                        Recognition from our robots speaking a negotiated
                        shoutout
                      </li>
                      <li>Small logo on Robot Jersey's</li>
                      <li>+ Bronze Benefits</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
               
              </Card>
            </Col>

            <Col lg={4}>
              <Card
                className="mb-4"
                style={{
                  minHeight: 240,
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
              >
                {/* <Card.Header
                  as="h2"
                  style={bronzeTierStyle}
                  className="text-center"
                >
                  Bronze Sponsorship
                </Card.Header> */}
                <Card.Title><Stack direction="horizontal" className="ms-auto" style={{justifyContent: "space-between"}}><b style={bronzeTierStyle}>Bronze Sponsorship</b><div>$1,000</div></Stack></Card.Title>
                <div
                    className="impact-line"
                    style={{
                      width: "100%",
                      height: "3px",
                      marginBottom: "10px",
                      display: "inline-block",
                      float: "right",
                    }}
                  />
                <Card.Body>
                  
                  <Card.Text>
                    Benefits:
                    <ul>
                      <li>Third sponsor listing</li>
                      <li>Brand logo on our website</li>
                      <li>Brand logo on our banner</li>
                      <li>Social media tag</li>
                    </ul>
                  </Card.Text>
                </Card.Body>

              </Card>
            </Col>
          </Row>
        )}

        <Container>
          <h5 className="text-center">
            Contact us at{" "}
            <a href={`mailto:${contactEmail}`}>
              <b>{contactEmail}</b>
            </a>{" "}
            {!runningOnBitbucket && "or fill in the below form below"} to
            discuss sponsorship opportunities.
          </h5>
        </Container>
        {!runningOnBitbucket && (
          <>
            <Container>
              <h4>Contact Us</h4>
            </Container>
            <SponsorForm />
          </>
        )}
      </Stack>
    </Container>
  );
}
