import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export function NavLink({ shortUrl, displayText, location }) {
  return (
    <Nav.Link
      as={Link}
      to={"/#" + shortUrl}
      className={`header-link ${
        location.hash === "#" + shortUrl ? "active" : ""
      }`}
    >
      {displayText}
    </Nav.Link>
  );
}
