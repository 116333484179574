import React from "react";
import { Container, Stack } from "react-bootstrap";

import team_members from "../data/TeamMembers.json";
import previous_teams from "../data/PreviousTeams.json";
import { TeamMembers } from "../components/TeamMember";
import { PageTitle } from "../components/PageTitle";
import { PreviousTeams } from "../components/PreviousTeams";

export default function About() {
  return (
    <Container className="page-container"  id="about">
      <Stack gap={2}>
        <PageTitle title={"The Team"} />
        <Container>
          <Stack gap={4}>
            <h4>Current Team Members</h4>
            <TeamMembers team_members={team_members} />
          </Stack>
        </Container>
        <Container style={{ marginTop: 50 }}>
          <h4>Previous Teams</h4>
          <Stack style={{ marginTop: 20 }} gap={5}>
            {previous_teams.map((team, index) => {
              return (
                <PreviousTeams
                  team={team}
                  key={index}
                  index={index}
                />
              );
            })}
          </Stack>
        </Container>
      </Stack>
    </Container>
  );
};
