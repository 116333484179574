import React from "react";
import { Container } from "react-bootstrap";

export function PageTitle({ title, colour }) {
  return (
    <>
      <Container className="pt-3">
        <h2 style={{ color: colour && colour, display: "inline", padding:"5px"}}>{title}</h2>
      </Container>
    </>
  );
}
