import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHash = ({headerRef}) => {
  const location = useLocation();

  useEffect(() => {
    const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        // Calculate the scroll position with the header offset
        const headerOffset = headerRef.current ? headerRef.current.offsetHeight : 0;
        const topPosition = element.offsetTop - headerOffset;

        console.log(headerRef)

        window.scrollTo({
          top: topPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [location, headerRef]); // Run the effect whenever the location changes

  return (
    <div ref={headerRef}></div> // A placeholder to pass as the header ref
  );
};

export default ScrollToHash;
