import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function TeamMembers({ team_members }) {
  const { innerWidth } = window;
  const [hiddenTextDict, setHiddenTextDict] = useState({});
  const [all_members, setAllMembers] = useState([]);


  useEffect(() => {
    let showText = {};
    team_members.forEach((team_member) => {
      showText[team_member.name] = innerWidth <= 768 ? false : true;
    });
    setHiddenTextDict(showText);

    const all_students = team_members.slice(2, -1);
    var detailed_students = [];
    var other_students = [];
    var picture_students = [];
    shuffle(all_students);
    all_students.forEach((student) => {
      if (student.description === "") {
        other_students.push(student);
      } else if (student.picture === "/images/headshots/headshot-placeholder.jpg"){
        detailed_students.push(student);
      }
      else {
        picture_students.push(student)
      }
    });
    const team_leaders = team_members.slice(0, 2);
    setAllMembers([...team_leaders, ...picture_students, ...detailed_students, ...other_students]);
  }, [team_members, innerWidth]);

  function toggleHiddenText(team_member) {
    setHiddenTextDict({
      ...hiddenTextDict,
      [team_member]: !hiddenTextDict[team_member],
    });
  }

  return (
    <Row xs={2} md={3} lg={4} xl={5} className="g-4">
      {all_members.map((team_member, index) => {
        return (
          <Col key={index}>
            <Card style={{ height: "100%", backgroundColor:"transparent", borderColor:"transparent"}}>
              <Card.Img variant="top" src={team_member.picture} />
              <Card.Body>
                <Card.Title>{team_member.name}</Card.Title>
                <div
                className="impact-line"
                style={{
                  width: "100%",
                  height: "3px",
                  marginBottom: "10px",
                  display: "inline-block",
                  float: "right",
                }}
           />
                <Card.Text
                  style={{ fontSize: "14px" }}
                  className={
                    hiddenTextDict[team_member.name] ? "" : "hidden-text"
                  }
                >
                  {team_member.description}
                </Card.Text>
                {team_member.description !== "" && innerWidth <= 768 && (
                  <Card.Link
                    className="text-muted"
                    onClick={() => {
                      toggleHiddenText(team_member.name);
                    }}
                  >
                    {hiddenTextDict[team_member.name]
                      ? "Show Less"
                      : "Show More"}
                  </Card.Link>
                )}
              </Card.Body>
              
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}
