import links from "../data/Links.json";

export default function Links() {
  return (
    <div className="container text-center py-5">
      <h2 className="mb-4">RedbackBots Links</h2>
      <div className="d-flex flex-column align-items-center">
        {links.map(link => (
          <a
            key={link.id}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary btn-lg mb-3 w-75"
            style={{ color: "white"}}
          >
            {link.label}
          </a>
        ))}
      </div>
    </div>
  );
}
