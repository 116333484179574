import React from "react";
import {
  Card,
  Row,
  Col,
} from "react-bootstrap";


export function AchievementCards({achievements}){
  
  return (
    <Row xs={1} md={2} lg={3} className="g-4">
      {achievements.map((achievement, idx) => (
        <Col key={idx}>
          <Card className="h-100" style={{backgroundColor:"transparent", borderColor:"transparent"}}>
            <Card.Body>
              <Card.Title>
                {achievement.title}
               
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                {achievement.subtitle}
                <div
                className="impact-line"
                style={{
                  width: "100%",
                  height: "3px",
                  marginBottom: "10px",
                  display: "inline-block",
                  float: "right",
                }}
           />
              </Card.Subtitle>
              <Card.Text>{achievement.description}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};